<template>
  <v-row no-gutters>
    <v-col cols="12">
      <redirect class="mb-5" title="Products" route="website.product-personalized" />
    </v-col>
    <v-col v-show="!loading" cols="12">
      <v-row no-gutters>
        <v-col cols="12" class="d-flex mb-7 align-center">
          <v-avatar tile size="70" color="#dddd" class="text-center pointer mr-3">
            <img
              :src="
                data && data.productId && data && data.productId.images[0]
                  ? imageHelpers.url(data && data.productId.imageSrcs[0])
                  : '@/assets/images/no-image.png'
              "
              alt=""
              srcset=""
            />
          </v-avatar>
          <h3 style="color:#4181cb">
            {{ data && data.productId ? data.productId.title : '' }}
          </h3>
        </v-col>
        <v-col cols="6">
          <div class="text-center mb-3" style=" width: 100%;">
            <h3>
              Image production setting
            </h3>
          </div>
          <v-col cols="12" class="product-canvas d-flex justify-center align-center">
            <div class="personalize-production"></div>
          </v-col>
        </v-col>
        <v-col cols="6">
          <div class="text-center mb-3" style="    width: 100%;">
            <h3>
              Image preview setting
            </h3>
          </div>
          <v-col cols="12" class=" preview-canvas d-flex justify-center align-center">
            <div class="personalize-preview"></div>
          </v-col>
        </v-col>
        <v-col cols="12" class="my-4">
          <v-divider class=""></v-divider>
        </v-col>

        <v-col cols="12">
          <v-btn @click="deleteItem" color="error">Delete</v-btn>
          <v-btn
            class="ml-2"
            @click="$router.push({ name: 'website.product-personalized.update', params: { id: $route.params.id } })"
            color="primary"
            >Update</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
    <!-- <div style="width: 100%; height: 100%" v-if="loading" class="d-flex justify-center align-center">
      <page-loader />
    </div> -->
    <v-overlay :value="overlay">
      <div class="fz-18">
        Upload image ....
        <v-progress-circular :size="30" color="primary" indeterminate></v-progress-circular>
      </div>
    </v-overlay>
  </v-row>
</template>

<script>
import { fabric } from 'fabric';
import $ from 'jquery';
import imageHelpers from '@/helpers/image';
import SelectionStyle from './components/SelectionStyle.vue';
import { log, parse } from 'handlebars';
import { imageFake } from '@/const/image';

import { personalizesApi } from '@/apis/personalizes';
import { uploadService } from '@/apis/upload.s';
import axios from 'axios';
export default {
  data() {
    return {
      imageFake,
      loading: false,
      e1: 1,
      imageHelpers,
      canvas: {
        product: null,
        preview: null,
      },
      optionsProductSetting: {
        align: 'center',
        bold: false,
        fontColor: '#000000',
        fontSize: '25px',
        italic: false,
        underline: false,
        fontFamily: '',
        effect: 'STRAIGHT',
      },
      optionsPreviewSetting: {
        align: 'center',
        bold: false,
        fontColor: '#000000',
        fontSize: '25px',
        italic: false,
        underline: false,
        fontFamily: '',
        effect: 'STRAIGHT',
      },
      overlay: false,
      formData: {
        previewJson: null,
        productionJson: null,
      },
      imageUrl: {
        product: null,
        preview: null,
      },

      image: {
        src: null,
        size: {
          width: 0,
          height: 0,
        },
      },
      data: null,
      maxWidth: 0,
      listFontSize: [],
      previewSetting: {
        product: null,
        preview: null,
      },
    };
  },
  created() {
    for (let i = 11; i < 40; i++) {
      this.listFontSize.push({ name: `${i}px`, id: i });
    }
  },
  async mounted() {
    this.previewSetting.product = new fabric.Canvas('cpp');
    this.previewSetting.preview = new fabric.Canvas('cprp');
    this.loading = true;

    await this.getData();
    this.loading = false;
  },
  methods: {
    async deleteItem() {
      try {
        let res = await personalizesApi.remove(this.$route.params.id);
        this.$router.push({ name: 'website.product-personalized' });
      } catch (error) {}
    },
    async getData() {
      try {
        let res = await personalizesApi.getById(this.$route.params.id);

        if (res.data) {
          this.data = res.data;
          let parser = new DOMParser();
          let xmlDocProduct = res.data.productionSvg
            ? parser.parseFromString(res.data.productionSvg, 'text/xml')
            : null;
          let xmlDocPreview = res.data.previewSvg ? parser.parseFromString(res.data.previewSvg, 'text/xml') : null;

          if (xmlDocProduct && xmlDocProduct.querySelector('image')) {
            xmlDocProduct.querySelector('svg').setAttribute('width', '100%');
            xmlDocProduct.querySelector('svg').setAttribute('height', '100%');
            let attributes = xmlDocProduct.querySelector('image').attributes;
            document.querySelector('.personalize-production').style.width = parseFloat(attributes.width.value) + `px`;
            document.querySelector('.personalize-production').innerHTML = xmlDocProduct.querySelector('svg').outerHTML;
          }
          if (xmlDocPreview && xmlDocPreview.querySelector('image')) {
            xmlDocPreview.querySelector('svg').setAttribute('width', '100%');
            xmlDocPreview.querySelector('svg').setAttribute('height', '100%');
            let attributes = xmlDocPreview.querySelector('image').attributes;
            document.querySelector('.personalize-preview').style.width = parseFloat(attributes.width.value) + `px`;
            document.querySelector('.personalize-preview').innerHTML = xmlDocPreview.querySelector('svg').outerHTML;
          }
        }
        console.log(res);
      } catch (error) {
        console.log('error', error);
      }
    },
  },
};
</script>

<style lang="scss">
.myFile {
  position: relative;
  overflow: hidden;
  float: left;
  clear: left;
}

.myFile input[type='file'] {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  font-size: 30px;
  filter: alpha(opacity=0);
}

.myFile2 input[type='file'] {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  font-size: 30px;
  filter: alpha(opacity=0);
}
.myFile2 {
  .btn-upload {
    background: #1f739e;
    font-weight: bold;
    color: white;
    padding: 8px 8px;
    display: flex;
    align-items: center;
    border-radius: 5px;
  }
}
</style>
